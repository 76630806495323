<template>
  <div class="bg">
    <div class="result" ref="capture" v-show="!photoShow">
      <div class="answer">
        <canvas ref="name" class="name-canvas"></canvas>
        <canvas ref="tips1" class="name-canvas margT5"></canvas>
      </div>
      <div class="result-tips" ref="resultBox">
        <img src="../assets/images/result_t1.png" alt="" />
      </div>
      <div class="scan">
        <div class="lft">
          <p class="t1">扫一扫 >></p>
          <p class="t2">看看我的匹配度</p>
          <span class="other">非严肃测试，可不要当真噢</span>
        </div>
        <div class="rgt">
          <img src="../assets/images/qrcode.png" alt="" />
        </div>
      </div>
    </div>
    <img
      :src="dataUrl"
      alt=""
      v-show="photoShow"
      ref="captureImg"
      class="capture"
    />
    <div class="pop" v-if="tips">
      <span>{{ tips }}</span>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      type: 0,
      name: "",
      dataUrl: "",
      photoShow: false,
      tips: "",
      //结果文字图定位
      resultTop: "",
    };
  },
  mounted() {
    if (this.$route.query.t && this.$route.query.n) {
      this.type = parseInt(this.$route.query.t);
      this.name = this.$route.query.n;
      setTimeout(() => {
        this.initRender();
      }, 500);
    } else {
      this.$router.replace("/");
    }
  },
  methods: {
    /**
     * 绘制渐变文字
     * obj {Object} 绘制的目标画板
     * fontOpt {Object} 文字配置，包含以下：
     * w {Float} 画板宽度
     * h {Float} 画板高度
     * bold {Boolean} 是否加粗
     * fontSize {Float} 字体大小
     * cont {String} 文字内容
     */
    drawCanvas(ogj, fontOpt) {
      //px转rem比例
      let textPx = (document.body.clientWidth * 75) / 750;
      //缩放比
      let scale =
        window.devicePixelRatio && window.devicePixelRatio > 1
          ? window.devicePixelRatio
          : 1;
      //画板对象
      let canvas = ogj;
      canvas.width = fontOpt.w * textPx * scale;
      canvas.height = fontOpt.h * textPx * scale;
      canvas.style.width = fontOpt.w * textPx + "px";
      canvas.style.height = fontOpt.h * textPx + "px";
      let context = canvas.getContext("2d");
      //设置字体样式
      context.font = `${fontOpt.bold ? "bold" : ""} ${fontOpt.fontSize *
        textPx *
        scale}px Arial`;
      context.textAlign = "center";
      //设置渐变
      let g = context.createLinearGradient(0, canvas.height, 0, 0);
      g.addColorStop("0", "#d69e61");
      g.addColorStop("1", "#fff");
      context.fillStyle = g;
      context.textBaseline = "middle";
      //填充内容
      context.fillText(
        fontOpt.cont,
        (fontOpt.w * textPx * scale) / 2,
        (fontOpt.h * textPx * scale) / 2
      );
      //内容按比例缩放，解决清晰度问题
      context.scale(scale, scale);
    },
    /**
     * 自动保存成图
     */
    saveImg() {
      //需要保存的对象
      let targetDom = this.$refs.capture;
      let box = window.getComputedStyle(targetDom);
      // Dom 计算宽高
      let width = parseInt(box.width, 10);
      let height = parseInt(box.height, 10);
      //像素比
      let scale =
        window.devicePixelRatio && window.devicePixelRatio > 1
          ? window.devicePixelRatio
          : 1;
      //设定画板对象
      let canvas = document.createElement("canvas");
      canvas.width = width * scale;
      canvas.height = height * scale;
      let render = (s = scale) => {
        let opts = {
          allowTaint: false,
          scale: s,
          canvas,
          useCORS: true,
          tainttest: false,
          width: width,
          height: height,
          x: 0,
        };

        setTimeout(() => {
          window.scrollTo(5, 0);
          html2canvas(targetDom, opts).then((res) => {
            let imgUrl = res.toDataURL("image/png", 1.0);
            this.dataUrl = imgUrl;
            if (this.dataUrl) {
              //如果没有读取到图片base64，讲缩放调为1，再次尝试渲染
              if (this.dataUrl == "data:,") {
                render(1);
              } else {
                this.photoShow = true;
              }
            }
          });
        }, 500);
      };
      render();
    },
    /**
     * 初始化渲染
     */
    initRender() {
      //名字
      this.drawCanvas(this.$refs.name, {
        w: 10,
        h: 0.8,
        bold: true,
        fontSize: 0.7,
        cont: this.name,
      });
      let random = Math.random() > 0.5 ? 1 : 0;
      if (this.type === 1) {
        //第一梯队
        this.resultTop = "top1";
        this.resultTips("有研有料的基金经理就是你哦");
      }
      // else if (this.type === 2) {
      //   //第二梯队
      //   if (random === 0) {
      //     this.resultTop = "top1";
      //     this.secondResult();
      //   } else {
      //     this.resultTop = "top2";
      //     this.secondResult2();
      //   }
      // } else {
      //   //第三梯队
      //   if (random === 0) {
      //     this.resultTop = "top3";
      //     this.thirdResult();
      //   } else {
      //     this.resultTop = "top3";
      //     this.thirdResult2();
      //   }
      // }
      this.resultAnimation();
    },
    /**
     * 文字动画
     */
    resultAnimation() {
      setTimeout(() => {
        this.$refs.resultBox.style.transform = "scale(1.1,1.1)";
        setTimeout(() => {
          this.$refs.resultBox.style.transform = "scale(1,1)";
          setTimeout(() => {
            this.tips = "正在生成图片...";
            this.saveImg();
            setTimeout(() => {
              this.tips = "";
              this.tips = "长按保存分享你的测试结果吧";
              setTimeout(() => {
                this.tips = "";
              }, 2000);
            }, 1000);
          }, 500);
        }, 300);
      }, 300);
    },
    /**
     * 名字下文字
     */
    resultTips(cont) {
      //提示
      this.drawCanvas(this.$refs.tips1, {
        w: 10,
        h: 0.7,
        bold: true,
        fontSize: 0.45,
        cont: cont,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #20150f;
  width: 100vw;
  height: 100vh;
}
.margT5 {
  margin-top: 5px !important;
}
.name-canvas {
  margin: 0 auto;
  display: block;
}
.result {
  background-image: url("../assets/images/result_bg_new.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;
  width: 100vw;
  height: 100vh;
  min-height: 1500px;
  background-color: #20150f;
  position: relative;
  .answer {
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
  }
  .result-tips {
    position: absolute;
    width: 100%;
    top: 670px;
    left: 0;
    transition: all 0.5s;
    img {
      width: 100%;
    }
  }
  .scan {
    position: absolute;
    top: 1220px;
    left: 0;
    padding: 0 75px;
    display: flex;
    width: 100%;
    .lft {
      .t1 {
        font-size: 52px;
        color: #fff;
      }
      .t2 {
        font-size: 35px;
        color: #fff;
      }
      .other {
        background-image: url("../assets/images/tips.png");
        width: 250px;
        height: 30px;
        display: block;
        background-size: 100%;
        background-repeat: no-repeat;
        font-size: 18px;
        padding-left: 15px;
        line-height: 30px;
        margin-top: 10px;
      }
    }
    .rgt {
      margin-left: auto;
      img {
        width: 183px;
        height: 183px;
        display: block;
        border: 6px solid #df9a81;
        border-radius: 6px;
      }
    }
  }
}
.capture {
  width: 100vw;
}
.pop {
  position: fixed;
  top: 40%;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  display: flex;
  justify-content: center;
  span {
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 70px;
    font-size: 26px;
  }
}
</style>
